@font-face {
  font-family: 'Open Sans';
  src: url('./fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Light Italic';
  src: url('./fonts/OpenSans-Light-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('./fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans Extra Bold';
  src: url('./fonts/OpenSans-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}